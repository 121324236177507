import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getUserAmountPaid } from "services/loanPaymentService";
import { saveLoanPayment } from "services/loanPaymentService";
import { getLoanAmount } from "services/loanService";
import { getLoanDetails } from "services/loanService";
import { getUserDisbursedLoans } from "services/loanService";

// components

export default function MakePayment() {
  let [loans, setLoans] = useState([]);
  let [loanValue, setLoanValue] = useState(0);
  let [amountPaid, setAmountPaid] = useState(0);
  let [loan, setLoan] = useState("");
  let [amount, setAmount] = useState(0);
  let [balance, setbalance] = useState(0);
  let [proof, setProof] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { data: newData } = await getUserDisbursedLoans();
      setLoans(newData);
    };
    fetchData().catch(console.error);
  }, []);

  async function submitLoanPayment() {
    toast.info("Processing");
      let totalPayment = parseInt(amountPaid) + parseInt(amount);
      console.log(parseInt(loanValue))
    if (parseInt(loanValue) < totalPayment)
      return toast.error(
        "Loan Value Exceeded! Kindly correct the value of payment"
      );
    let data = {
      loanId: loan,
      amount,
    };

    const formData = new FormData();
    formData.append("File", proof);
    formData.append("details", JSON.stringify(data));

    try {
      await saveLoanPayment(formData);
      toast.success("Loan Payment submitted for Confirmation");
      toast.info("Upon confirmation, payment will reflect on your dashboard");
      window.location = "/admin/myLoanPayments";
    } catch (err) {
      toast.error(err.response?.data);
    }
  }

  async function calculateBalanceDue(loanId) {
    if (parseInt(loanId) !== 0) {
      toast.info("Fetching Loan Details");
      let paymentsMade = 0;
      let loanValue = 0;
      const { data: amountPaid } = await getUserAmountPaid(loanId);
     // const { data: loanDetails } = await getLoanDetails(loanId);
      const { data: loanAmount } = await getLoanAmount(loanId);
      if (parseInt(amountPaid[0]?.amount_paid) >= 0)
        paymentsMade = parseInt(amountPaid[0]?.amount_paid);
      if (parseInt(loanAmount[0]?.amount) >= 0)
        loanValue = parseInt(loanAmount[0]?.amount);
      let finalValue = loanValue - paymentsMade;
      setLoanValue(loanAmount[0].amount);
      setAmountPaid(paymentsMade);
      setbalance(finalValue);
      toast.info("Fetching Completed");
    } else {
      setLoanValue(0);
      setAmountPaid(0);
      setbalance(0);
    }
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Make Payment
                      </h6>
                      <p style={{color:"red"}}>NOTE: Pending payments will be calculated</p>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-sm mt-3 mb-6 font-bold uppercase">
              AMOUNT DUE: {balance.toLocaleString("en-US")} naira
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Select Loan
                  </label>
                  <select
                    type="select"
                    value={loan}
                    onChange={(e) => {
                      setLoan(e.target.value);
                      calculateBalanceDue(e.target.value);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option key="0" value="0">
                      Select Loan
                    </option>
                    {loans.map((item) => (
                      <option value={item._id}>
                        {item.date_created} -{" "}
                        {item.amount?.toLocaleString("en-US")} naira
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Balance
                  </label>
                  <p> LOAN VALUE: {loanValue.toLocaleString("en-US")} naira</p>
                  <p> PAYMENTS MADE: {amountPaid.toLocaleString("en-US")} naira</p>
                  <p style={{ color: "red" }}> AMOUNT DUE: {balance.toLocaleString("en-US")} naira</p>
                </div>
              </div>
            </div>
            <hr />
            <br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Payment in Naira
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Payment Proof
                  </label>
                  <input
                    type="file"
                    onChange={(e) => {
                      setProof(e.target.files[0]);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Submit
                  </label>
                  <button
                    type="button"
                    style={{ backgroundColor: "#558000", color: "white" }}
                    onClick={() => {
                      submitLoanPayment();
                    }}
                    className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >
                    Submit Payment Proof
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
