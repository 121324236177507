import axios from 'axios'
import logger from './logService'
import { toast } from 'react-toastify'
//import auth from "./authService";
const fixer = 'https://esl-backend-node-9aa23dd1b5bc.herokuapp.com/api/'
//axios.defaults.baseURL = process.env.REACT_APP_API_URL || fixer;
axios.defaults.baseURL = fixer

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500

  if (!expectedError) {
    logger.log(error)
    toast.error('An unexpected error occurrred.')
  }

  return Promise.reject(error)
})

function setJwt(jwt) {
  axios.defaults.headers.common['x-auth-token'] = jwt
}

const links = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
}
export default links
