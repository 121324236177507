import React, {useEffect, useState} from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import auth from '../services/authService';



// views

import Dashboard from "views/dashboard/Dashboard.js";
import SidebarUser from "components/Sidebar/SideBarUser";
import UpdateDetails from "views/admin/User/UpdateDetails";
import { getUserDetails } from "services/userService";

export default function Admin() {
  const user = auth.getCurrentUser()
  let [userDetails, setUserDetails] = useState([])

  if (user && user?.exp === undefined) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }

  if (user && user?.exp * 1000 < Date.now()) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data: newData } = await getUserDetails();
      setUserDetails(newData[0]);
    };
    fetchData().catch(console.error);
  }, []);

 if(userDetails?.guarantor === "PENDING") return window.location =  "/user/updateDetails"


  return (
    <>
      {user?.user_type === 'ADMIN' && (
        <Sidebar />
      )}
            {user?.user_type === 'USER' && (
        <SidebarUser />
      )}
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
          <Route path="/dashboard/dashboard" exact component={Dashboard} />
          <Route path="/dashboard/updateDetails" exact component={UpdateDetails} />
            <Redirect from="/dashboard" to="/dashboard/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
