import http from "./httpService";

const apiEndpoint = "/shares";

function sharesUrl(id) {
  return `${apiEndpoint}/${id}`;
}
export function getShares() {
  return http.get(apiEndpoint);
}

export function getPendingShares() {
  return http.get(apiEndpoint + "/pendingSharesPurchase");
}

export function getUserShares() {
  return http.get(apiEndpoint + "/user");
}

export function getCountTotalApprovedShares() {
    return http.get(apiEndpoint + "/countTotalApprovedSharesUser");
}

export function getTotalApprovedShares() {
  return http.get(apiEndpoint + "/countTotalApprovedShares");
}

export function getSharesReport() {
  return http.get(apiEndpoint + "/sharesReport");
}
  
export function getCountTotalPendingShares() {
    return http.get(apiEndpoint + "/countTotalPendingShares");
  }

export function approveShare(id) {
  return http.put(apiEndpoint + "/approveShares/" + id);
}    

export function deleteShare(id) {
  return http.delete(sharesUrl(id));
}


export async function saveShare(data) {
  return http.post(apiEndpoint, data);
}

