import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { guarantorDecision } from "services/loanService";
import { getPendingLoansGuarantor } from "services/loanService";

// components

export default function PendingLoansGuarantor() {
  let [shares, setShares] = useState([]);

  let color = "light";

  let testDesign1 =
    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100 ";
  let testDesign2 =
        "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4";
    let testDesign3 = " active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
  useEffect(() => {
    const fetchData = async () => {
      const { data: newData } = await getPendingLoansGuarantor();
      setShares(newData);
    };
    fetchData().catch(console.error);
  }, []);

  async function guarantorDecisionFunc(decision, id) {
      toast.info("Processing");
      let data;
      if (decision === "GUARANTOR_APPROVED") data = {status:"PENDING", decision:"GUARANTOR_APPROVED"}
      if (decision === "REJECTED") data = {status:"REJECTED", decision:"REJECTED"}
    try {
      await guarantorDecision(data, id);
      toast.success("Processed Successfully");
      window.location.reload();
    } catch (error) {
      toast.error(error);
    }
  }
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Pending loan Request for your Pre-Review
              </h3>
              <br />
              <p>Total: {shares.length}</p>
              <p style={{ color: "red" }}>
                NOTE: All loan request you approve, if the user refuses to pay,
                you will be held accountable for it
              </p>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className={testDesign1}>Request Date</th>
                <th className={testDesign1}>Name</th>
                <th className={testDesign1}>Amount</th>
                <th className={testDesign1}>Loan Tenure</th>
                <th className={testDesign1}>Action</th>
              </tr>
            </thead>
            <tbody>
              {shares.map((item, index) => (
                <tr v-for="item in tableItems" key={index}>
                  <td className={testDesign2}>{item.date_created}</td>
                  <td className={testDesign2}>{item.name}</td>
                  <td className={testDesign2}>{item.amount.toLocaleString("en-US")} naira</td>
                  <td className={testDesign2}>3 Months</td>
                  <td className={testDesign2}>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#558000",
                        color: "white",
                        width: "auto",
                      }}
                      onClick={() => {
                        guarantorDecisionFunc("GUARANTOR_APPROVED", item._id);
                      }}
                      className={testDesign3}
                    >
                      APPROVE
                    </button>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        width: "auto",
                      }}
                      onClick={() => {
                        guarantorDecisionFunc("REJECTED", item._id);
                      }}
                      className={testDesign3}
                    >
                      REJECT
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
