import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function FooterSmall(props) {
  return (
    <>
      <footer
        className={
          (props.absolute
            ? "absolute w-full bottom-0 bg-blueGray-800"
            : "relative") + " pb-6"
        }
      >
        <div className="container mx-auto px-4">
          <hr className="mb-6 border-b-1 border-blueGray-600" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4">
              <div className="text-sm text-white font-semibold py-1 text-center md:text-left">
              Copyright © {new Date().getFullYear()} Esteem Support & Leverage(ESL) Millionaires
              </div>
            </div>
            <div className="w-full md:w-8/12 px-4">
              <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                <li>
                <Link to="/termsAndConditions" style={{color:"white"}}>
                    Terms and Conditions
                  </Link>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
