import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function TermsAndConditions() {
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="text-center mt-12">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                   ESL -  Terms and Conditions
                  </h3>
                </div>
                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• You must have a Gmail account.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">•	You must have an online Banking system.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• You must be Active on WhatsApp group.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Cost per share NGN25,000.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Highest share per month 16 shares (NGN 400,000)..</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Minimum share per month 1 share (NGN 25,000) (mandatory).</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Timeline for purchasing shares ends on the 10th of the following month.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• The loan charge is 10% compound interest.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Loan duration 3 Months from the date of collection.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Loan duration exceeding 3 months attracts 5% charges per month of the total balance.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Shares cannot be purchased in arrears.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Shares investment Purchase starts January and ends in October.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• November will be used to collate all outstanding loans.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Final share out begins from 10th December.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• Any month you don’t purchase shares, you will not accrue dividend. </p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• You stand as a guarantor for any member you introduce to the scheme any default from the person you introduced will be deducted from your savings.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• If you opt out of the scheme at any point before 30th November, you will take 95% of your total savings without any ROI..</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• If you default loan payment after 30th November (share out) you will lose 10% of your total savings.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">• At the end of the share investment cycle, 100% of your total investment and 70% Return on Investment shall be paid.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">•	All members are required to take loan at least once a year</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700"><b>Loan modality:</b></p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">o	Send a request on the platform with amount and name of guarantor</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">o	State your commitment to pay.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">o	Guarantor sends her/his acceptance message</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">o	The member will confirm receipt of funds.</p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700"><b>NOTE: If you guarantee anyone that defaults, the amount will be deducted from your contributions</b></p>
                    <p className="mb-4 text-lg leading-relaxed text-blueGray-700">Thank you and looking forward to a fruitful 2024!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
