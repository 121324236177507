import http from "./httpService";

const apiEndpoint = "/loanPayments";

function loanUrl(id) {
  return `${apiEndpoint}/${id}`;
}
export function getLoanPayments() {
  return http.get(apiEndpoint);
}

export function getPendingLoanPayments() {
  return http.get(apiEndpoint + "/pendingLoanPayments");
}

export function getConfirmedLoanPayments() {
    return http.get(apiEndpoint + "/confirmedLoanPayments");
}

export function getUserLoanPayments() {
  return http.get(apiEndpoint + "/user");
}

export function getUserAmountPaid(id) {
    return http.get(apiEndpoint + "/userLoanPayments/" + id);
  }

export function processLoanPayment(decision, id) {
  return http.put(apiEndpoint + "/processLoanPayment/" + id, decision);
}   


export function deleteLoanPayment(id) {
  return http.delete(loanUrl(id));
}


export async function saveLoanPayment(data) {
  return http.post(apiEndpoint, data);
}

