import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import auth from '../services/authService';



// views

import SidebarUser from "components/Sidebar/SideBarUser";
import UpdateDetails from "views/admin/User/UpdateDetails";
import HeaderStatsOthers from "components/Headers/HeaderStatsOthers";

export default function UserLayout() {
  const user = auth.getCurrentUser()

  if (user && user?.exp === undefined) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }

  if (user && user?.exp * 1000 < Date.now()) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }

  return (
    <>
      {user?.user_type === 'ADMIN' && (
        <Sidebar />
      )}
            {user?.user_type === 'USER' && (
        <SidebarUser />
      )}
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStatsOthers />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
          <Route path="/user/updateDetails" exact component={UpdateDetails} />
            <Redirect from="/dashboard" to="/dashboard/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
