import http from "./httpService";

const apiEndpoint = "/loans";

function loanUrl(id) {
  return `${apiEndpoint}/${id}`;
}
export function getLoans() {
  return http.get(apiEndpoint);
}

export function getPendingLoans() {
  return http.get(apiEndpoint + "/pendingLoans");
}

export function getPastDueLoans() {
  return http.get(apiEndpoint + "/pastDue");
}

export function getPendingLoansGuarantor() {
    return http.get(apiEndpoint + "/guarantorPending");
}
  
export function getLoansGuarantor() {
    return http.get(apiEndpoint + "/guarantor");
  }

export function getUserLoans() {
  return http.get(apiEndpoint + "/user");
}

export function getUserDisbursedLoans() {
    return http.get(apiEndpoint + "/userDisbursed");
  }

export function getCountTotalApprovedLoans() {
    return http.get(apiEndpoint + "/countTotalApprovedLoans");
}
  
export function getCountTotalPendingLoans() {
    return http.get(apiEndpoint + "/countTotalPendingLoans");
}

export function getLoanAmount(id) {
  return http.get(apiEndpoint + "/getLoanAmount/" + id);
}  
  
export function getLoanDetails(id) {
    return http.get(loanUrl(id));
  }

export function guarantorDecision(decision, id) {
  return http.put(apiEndpoint + "/guarantorDecision/" + id, decision);
}   

export function processLoan(decision, id) {
    return http.put(apiEndpoint + "/processLoan/" + id, decision);
}  
  
export function declineLoan(decision, id) {
  return http.put(apiEndpoint + "/declineLoan/" + id, decision);
}  

export function deleteLoan(id) {
  return http.delete(loanUrl(id));
}

export function confirmReceipt(id) {
  return http.put(apiEndpoint + "/confirmReceipt/" + id);
}


export async function saveLoanRequest(data) {
  return http.post(apiEndpoint, data);
}

