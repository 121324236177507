import React, { useState, useEffect } from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import { getValues } from "services/dashboardService";

export default function HeaderStats() {
  let [approvedShares, setApprovedShares] = useState(0);
  let [pendingShares, setPendingShares] = useState(0);
  let [approvedUsers, setApprovedUsers] = useState(0);
  let [pendingUsers, setPendingUsers] = useState(0);
  let [approvedLoans, setApprovedLoans] = useState(0);
  let [pastDueLoans, setPastDueLoans] = useState(0);
  let [pendingLoans, setPendingLoans] = useState(0);
  let [approvedLoansValue, setApprovedLoansValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let approvedLoansValue = 0;
      const { data: approvedShares } = await getValues("approvedShares");
      setApprovedShares(approvedShares.count);

      const { data: pendingShares } = await getValues("pendingShares");
      setPendingShares(pendingShares.count);

      const { data: approvedUsers } = await getValues("approvedUsers");
      setApprovedUsers(approvedUsers.count);

      const { data: pendingUsers } = await getValues("pendingUsers");
      setPendingUsers(pendingUsers.count);

      const { data: approvedLoans } = await getValues("activeLoans");
      setApprovedLoans(approvedLoans.count);
      if (approvedLoans.amount !== null) {
        console.log(approvedLoans.amount)
        approvedLoansValue = approvedLoans.amount;
        setApprovedLoansValue(approvedLoansValue);
      }

      const { data: pastDueLoans } = await getValues("pastDueLoans");
      setPastDueLoans(pastDueLoans.count);

      const { data: pendingLoans } = await getValues("pendingLoans");
      setPendingLoans(pendingLoans.count);


    };
    fetchData().catch(console.error);
  }, []);

  return (
    <>
      {/* Header */}
      <div
        className="relative  md:pt-32 pb-32 pt-12"
        style={{ backgroundColor: "#607d3b" }}
      >
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle=" SHARES"
                  statTitle={approvedShares?.toLocaleString("en-US")}
                  statArrow="up"
                  statPercent={pendingShares?.toLocaleString("en-US")}
                  statPercentColor="text-red-500"
                  statDescripiron="Pending"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle=" USERS"
                  statTitle={approvedUsers?.toLocaleString("en-US")}
                  statArrow="down"
                  statPercent={pendingUsers}
                  statPercentColor="text-red-500"
                  statDescripiron="Pending Confirmation"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="ACTIVE LOANS"
                  statTitle={approvedLoansValue?.toLocaleString("en-US")}
                  statArrow="down"
                  statPercent={approvedLoans?.toLocaleString("en-US")}
                  statPercentColor="text-emerald-500"
                  statDescripiron="Loans"
                  statIconName="fas fa-users"
                  statIconColor="bg-pink-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="PAST DUE"
                  statTitle={pastDueLoans?.toLocaleString("en-US")}
                  statArrow="up"
                  statPercent={pendingLoans?.toLocaleString("en-US")}
                  statPercentColor="text-red-500"
                  statDescripiron="Pending Loans"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
