import React, { useState } from "react";
import { toast } from "react-toastify";
import { saveLoanRequest } from "services/loanService";

// components

export default function NewLoan() {
  let [guarantor, setGuarantor] = useState("");
  let [amount, setAmount] = useState(0);

  let testStyle1 =
    "border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150";

  async function submitLoanRequest(id) {
    let data = {
      amount,
      guarantor,
    };
    toast.info("Processing");
    try {
        await saveLoanRequest(data);
        
      toast.success("Loan Request Sent to Guarantor For Pre-Approval");
      toast.info("Kindly follow up with your guarantor for fast approval");
      window.location = "/admin/myLoans";
    } catch (err) {
        toast.error(err.response?.data);
    }
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              New Loan Request Form
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Amount in Naira
                  </label>
                  <input
                    type="number"
                    className={testStyle1}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Guarantor's Email
                  </label>
                  <input
                    type="email"
                    className={testStyle1}
                    placeholder="jesse@example.com"
                    value={guarantor}
                    onChange={(e) => setGuarantor(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Kindly note the below terms and conditions
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    1. All loans are to be paid in 3 months{" "}
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    2. After the first 3 months, an additional 5% interest will
                    be added to the loan monthly
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    3. All loans attract an interest of 10%
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    4. Your Guarantor will pay the loan you are requesting so
                    approval from your guarantor is required{" "}
                  </label>
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Submit Request
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <button
                    type="button"
                    style={{ backgroundColor: "#558000", color: "white" }}
                    onClick={() => {
                      submitLoanRequest();
                    }}
                    className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >
                    Submit Loan Purchase
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
