import React, { useState } from "react";
import { toast } from "react-toastify";
import { saveShare } from "services/sharesService";

// components

export default function NewShares() {
  let [total, setTotal] = useState(0);
  let [sharesValue, setSharesValue] = useState(0);
  let [proof, setProof] = useState();

  async function submitPurchase() {
    toast.info("Processing");
    let data = {
      total,
    };

    const formData = new FormData();
    formData.append("File", proof);
    formData.append("details", JSON.stringify(data));

    try {
      await saveShare(formData);
      toast.success("Shares Purchase submitted for Confirmation");
      toast.info("Upon confirmation, payment will reflect on your dashboard");
      window.location = "/admin/myShares";
    } catch (error) {
      toast.error(error.response?.data);
    }
  }

  function calculateSharesValue(total) {
    let value = 25000;
    let selection = parseInt(total);
    let finalValue = value * selection;
    setSharesValue(finalValue.toLocaleString("en-US"));
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              New Share Purchase
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-sm mt-3 mb-6 font-bold uppercase">
              AMOUNT DUE: {sharesValue} naira
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Quantity
                  </label>
                  <select
                    type="select"
                    value={total}
                    onChange={(e) => {
                      setTotal(e.target.value);
                      calculateSharesValue(e.target.value);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option key="0" value="0">
                      Select Quantity
                    </option>
                    <option key="1" value="1">
                      1
                    </option>
                    <option key="2" value="2">
                      2
                    </option>
                    <option key="3" value="3">
                      3
                    </option>
                    <option key="4" value="4">
                      4
                    </option>
                    <option key="5" value="5">
                      5
                    </option>
                    <option key="6" value="6">
                      6
                    </option>
                    <option key="7" value="7">
                      7
                    </option>
                    <option key="8" value="8">
                      8
                    </option>
                    <option key="9" value="9">
                      9
                    </option>
                    <option key="10" value="10">
                      10
                    </option>
                    <option key="11" value="11">
                      11
                    </option>
                    <option key="12" value="12">
                      12
                    </option>
                    <option key="13" value="13">
                      13
                    </option>
                    <option key="14" value="14">
                      14
                    </option>
                    <option key="15" value="15">
                      15
                    </option>
                    <option key="16" value="16">
                      16
                    </option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Payment Proof
                  </label>
                  <input
                    type="file"
                    onChange={(e) => {
                      setProof(e.target.files[0]);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Submit
                  </label>
                  <button
                    type="button"
                    style={{ backgroundColor: "#558000", color: "white" }}
                    onClick={() => {
                      submitPurchase();
                    }}
                    className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >
                    Submit Share Purchase
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
