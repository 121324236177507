import React from "react";

// components

export default function HeaderStatsOthers() {
  return (
    <>
      {/* Header */}
      <div className="relative  md:pt-32 pb-20 pt-12" style={{backgroundColor:"#607d3b"}}>
      </div>
    </>
  );
}
