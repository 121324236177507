import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";

import { getValues } from "services/dashboardService";


let CanvasJSChart = CanvasJSReact.CanvasJSChart

export default function CardBarChart() {
  let [sharesReport, setSharesReport] = useState([]);
  let [loansReports, setLoansReports] = useState([]);



  useEffect(() => {
    let monthsArray = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },
    ];
    const fetchData = async () => {
      const { data: sharesReport } = await getValues("sharesReport");
      const sharesData = [];
      sharesReport.map(function (row) {
        let getMonthName = monthsArray.find(x => x.id === row.months)
        return sharesData.push({ label: getMonthName.name, y: row.total });
      });
      setSharesReport(sharesData);
      const { data: loansReport } = await getValues("loansReport");
      const loansData = [];
      loansReport.map(function (row) {
        let getMonthName = monthsArray.find(x => x.id === row.months)
        return loansData.push({ label: getMonthName.name, y: row.amount });
      });
      setLoansReports(loansData);

    };
    fetchData().catch(console.error);
  }, []);

  let optionSharesReport = {
    exportEnabled: true,
    animationEnabled: true,
    data: [
      {
        type: 'doughnut',
        startAngle: 75,
        toolTipContent: '<b>{label}</b>: {y} Shares',
        showInLegend: 'true',
        legendText: '{label}',
        indexLabelFontSize: 16,
        indexLabel: '{label} - {y} Shares',
        dataPoints: sharesReport,
      },
    ],
  }

  let optionLoansReport = {
    exportEnabled: true,
    animationEnabled: true,
    data: [
      {
        type: 'pie',
        startAngle: 75,
        toolTipContent: '<b>{label}</b>: {y} Naira',
        showInLegend: 'true',
        legendText: '{label}',
        indexLabelFontSize: 16,
        indexLabel: '{label} - {y} Naira',
        dataPoints: loansReports,
      },
    ],
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Approved Shares
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
                Total Shares
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
          <CanvasJSChart options={optionSharesReport} />
          </div>
        </div>
      </div>
      <br />
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
                Disbursed Loans
              </h6>
              <h2 className="text-blueGray-700 text-xl font-semibold">
                Total Loans
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
          <CanvasJSChart options={optionLoansReport} />
          </div>
        </div>
      </div>
    </>
  );
}
