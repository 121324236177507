import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { confirmReceipt } from "services/loanService";
import { deleteLoan } from "services/loanService";
import { getUserLoans } from "services/loanService";

// components

export default function UserLoans() {
  let [shares, setShares] = useState([]);

  let color = "light";

  let testDesign1 =
    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100 ";
  let testDesign2 =
    "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4";
  let testDesign3 =
    " active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150";

  useEffect(() => {
    const fetchData = async () => {
      const { data: newData } = await getUserLoans();
      setShares(newData);
    };
    fetchData().catch(console.error);
  }, []);

  async function deleteLoanFunc(id) {
    toast.info("Processing");
    try {
      await deleteLoan(id);
      toast.success("Loan Deleted Successfully");
      window.location.reload();
    } catch (err) {
      toast.error(err.response?.data);
    }
  }

  async function confirmReceiptFunc(id) {
    toast.info("Processing");
    try {
      await confirmReceipt(id);
      toast.success("Loan Receipt Confirmed Successfully");
      window.location.reload();
    } catch (err) {
      toast.error(err.response?.data);
    }
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                My Loan Requests
              </h3>
              <br />
              <p>Total: {shares.length}</p>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className={testDesign1}>Request Date</th>
                <th className={testDesign1}>Name</th>
                <th className={testDesign1}>Amount</th>
                <th className={testDesign1}>Guarantor</th>
                <th className={testDesign1}>Guarantor Decision</th>
                <th className={testDesign1}>Status</th>
              </tr>
            </thead>
            <tbody>
              {shares.map((item, index) => (
                <tr v-for="item in tableItems" key={index}>
                  <td className={testDesign2}>{item.date_created}</td>
                  <td className={testDesign2}>{item.name}</td>
                  <td className={testDesign2}>
                    {item.amount.toLocaleString("en-US")} naira
                  </td>
                  <td className={testDesign2}>{item.guarantor}</td>
                  <td className={testDesign2}>{item.guarantor_decision}</td>
                  <td className={testDesign2}>{item.status}</td>
                  {item.status === "PENDING" && (
                    <td className={testDesign2}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          width: "auto",
                        }}
                        onClick={() => {
                          deleteLoanFunc(item._id);
                        }}
                        className={testDesign3}
                      >
                        DELETE
                      </button>
                    </td>
                  )}
                  {item.confirm_receipt === "PENDING" && (
                    <td className={testDesign2}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#558000",
                          color: "white",
                          width: "auto",
                        }}
                        onClick={() => {
                          confirmReceiptFunc(item._id)
                        }}
                        className={testDesign3}
                      >
                        CONFIRM RECEIPT
                      </button>
                    </td>
                  )}
                  {item.status === "DISBURSED" &&
                    item.confirm_receipt === "CONFIRMED" && (
                      <td className={testDesign2}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "#558000",
                            color: "white",
                            width: "auto",
                          }}
                          onClick={() => {
                            window.location = "/admin/makePayment";
                          }}
                          className={testDesign3}
                        >
                          MAKE PAYMENT
                        </button>
                      </td>
                    )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
