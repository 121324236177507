import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import ResetPassword from "views/auth/ResetPassword.js";
import GenerateResetCode from "views/auth/GenerateResetCode.js";

import url from "../assets/img/esl_wall_wall.jpg"

import auth from "./../services/authService";


export default function Auth() {

  const jwt = localStorage.getItem('eslSystem')
  if (jwt) return (window.location = '/dashboard/dashboard')

  const user = auth.getCurrentUser();
  if (user && user?.exp === undefined) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }

  if (user && user?.exp * 1000 < Date.now()) {
    localStorage.removeItem("eslSystem");
    window.location = "/auth/login";
  }


  return (
    <>
      <Navbar transparent />
      <main>
        <section  className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full"
            style={{
              backgroundImage: `url('${url}')`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",}}
          ></div>
          
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/resetPassword" exact component={ResetPassword} />
            <Route path="/auth/resetPassword/:id" exact component={ResetPassword} />
            <Route path="/auth/generateResetCode" exact component={GenerateResetCode} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
