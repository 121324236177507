import http from "./httpService";

const apiEndpoint = "/users";

function staffUrl(id) {
  return `${apiEndpoint}/${id}`;
}
export function getUsers() {
  return http.get(apiEndpoint);
}

export function getUserDetails() {
  return http.get(apiEndpoint + "/me");
}

export function getPendingUsers() {
  return http.get(apiEndpoint + "/pending");
}

export function getSuspendedUsers() {
  return http.get(apiEndpoint + "/suspended");
}

export function suspendActiveUser(id) {
  return http.put(apiEndpoint + "/suspendUser/" + id);
}

export function changeAccountType(id, data) {
  return http.put(apiEndpoint + "/changeAccountType/" + id, data);
}

export function activateUser(id) {
  return http.put(apiEndpoint + "/activateUser/" + id);
}

export function updateGuarantor(data) {
  return http.put(apiEndpoint + "/guarantor", data);
}

export function approveUser(id) {
  return http.put(apiEndpoint + "/approveUser/" + id);
}

export function deleteUser(id) {
  return http.delete(staffUrl(id));
}

export function getUser(id) {
  return http.get(staffUrl(id));
}

export async function saveUser(data) {
  return http.post(apiEndpoint, data);
}

export async function userCP(data) {
  return http.post(apiEndpoint + "/cp", data);
}
export async function editUser(data) {
  const body = { ...data };
  delete body._id;

  return http.put(staffUrl(data._id), body);
}

//Password Reset

export function getResetCode(email) {
  return http.get(apiEndpoint + "/getResetCode/" + email);
}

export function userPasswordReset(code, data) {
  return http.put(apiEndpoint + "/resetPassword/" + code, data);
}

export function adminReset(id) {
  return http.put(apiEndpoint + "/adminReset/" + id);
}
