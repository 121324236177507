import React, { useState, useEffect } from "react";
import { getShares } from "services/sharesService";

// components


export default function Shares() {
  let [shares, setShares] = useState([]);

  let color = "light";

  let testDesign1 =
    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100 ";
  let testDesign2 =
    "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4";
  useEffect(() => {
    const fetchData = async () => {
      const { data: newData } = await getShares();
      setShares(newData);
    };
    fetchData().catch(console.error);
  }, []);


  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Approved Shares
              </h3>
              <p>Total: {shares.length}</p>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className={testDesign1}>Date</th>
                <th className={testDesign1}>Name</th>
                <th className={testDesign1}>Shares Purchased</th>
                <th className={testDesign1}>Proof</th>
              </tr>
            </thead>
            <tbody>
              {shares.map((item, index) => (
                <tr v-for="item in tableItems" key={index}>
                  <td className={testDesign2}>{item.date_created}</td>
                  <td className={testDesign2}>{item.name}</td>
                  <td className={testDesign2}>{item.total}</td>
                  <td className={testDesign2}><a target="_blank" rel="noreferrer" href={item.file}>View</a></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
