import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { changeAccountType, suspendActiveUser, adminReset } from "services/userService";

// components

import { getUsers } from "services/userService";

export default function Users() {
  let [users, setUsers] = useState([]);

  let color = "light";

  let testDesign1 =
    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100 ";
  let testDesign2 =
    "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4";
  useEffect(() => {
    const fetchData = async () => {
      const { data: newData } = await getUsers();
      setUsers(newData);
    };
    fetchData().catch(console.error);
  }, []);

  async function suspendUser(id) {
    toast.info("Processing");
    try {
      await suspendActiveUser(id);
      toast.success("User Account Suspended");
      window.location.reload();
    } catch (error) {
      toast.error(error);
    }
  }
  async function upgradeUser(id, userType) {
    toast.info("Processing");
    let data = {
      userType
    }
    try {
      await changeAccountType(id, data);
      toast.success("User Account Suspended");
      window.location.reload();
    } catch (err) {
      toast.error(err.response?.data);    }
  }
  async function funcAdminReset(id) {
    toast.info("Processing");
    try {
      await adminReset(id);
      toast.success("User's Password Set to Default");
    } catch (err) {
      toast.error(err.response?.data);    }
  }
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                List of all Confirmed Users
              </h3>
              <p>Total: {users.length}</p>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className={testDesign1}>Name</th>
                <th className={testDesign1}>Email</th>
                <th className={testDesign1}>Guarantor</th>
                <th className={testDesign1}>Phone Number</th>
                <th className={testDesign1}>Address</th>
                <th className={testDesign1}>Next of KIN</th>
                <th className={testDesign1}>NOK Address</th>
                <th className={testDesign1}>NOK Phone Number</th>
                <th className={testDesign1}>Account Name</th>
                <th className={testDesign1}>Bank Name</th>
                <th className={testDesign1}>Account Number</th>
                <th className={testDesign1}>Approved By</th>
                <th className={testDesign1}>Date Approved</th>
                <th className={testDesign1}>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map((item, index) => (
                <tr v-for="item in tableItems" key={index}>
                  <td className={testDesign2}>{item.name}</td>
                  <td className={testDesign2}>{item.email}</td>
                  <td className={testDesign2}>{item.guarantor}</td>
                  <td className={testDesign2}>{item.phone_number}</td>
                  <td className={testDesign2}>{item.address}</td>
                  <td className={testDesign2}>{item.nok_name}</td>
                  <td className={testDesign2}>{item.nok_address}</td>
                  <td className={testDesign2}>{item.nok_phone_number}</td>
                  <td className={testDesign2}>{item.account_name}</td>
                  <td className={testDesign2}>{item.bank_name}</td>
                  <td className={testDesign2}>{item.account_number}</td>
                  <td className={testDesign2}>{item.approved_by}</td>
                  <td className={testDesign2}>{item.date_approved}</td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    {item.user_type === "ADMIN" && (
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#558000",
                          color: "white",
                          width: "auto",
                        }}
                        onClick={() => {
                          upgradeUser(item._id, "USER");
                        }}
                        className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      >
                        Make User
                      </button>
                    )}
                    {item.user_type === "USER" && (
                      <button
                        type="button"
                        style={{
                          backgroundColor: "purple",
                          color: "white",
                          width: "auto",
                        }}
                        onClick={() => {
                          upgradeUser(item._id, "ADMIN");
                        }}
                        className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      >
                        Make Admin
                      </button>
                    )}
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#610c04",
                        color: "white",
                        width: "auto",
                      }}
                      onClick={() => {
                        suspendUser(item._id);
                      }}
                      className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    >
                      Suspend
                    </button>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#00aead",
                        color: "white",
                        width: "auto",
                      }}
                      onClick={() => {
                        funcAdminReset(item._id);
                      }}
                      className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    >
                      Reset Password
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
