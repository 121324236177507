import React, { useState } from "react";
import { toast } from "react-toastify";
import { saveLoanRequest } from "services/loanService";
import { userCP } from "services/userService";

// components

export default function ChangePassword() {
  let [currentPassword, setCurrentPassword] = useState("");
  let [newPassword1, setNewPassword1] = useState("");
  let [newPassword2, setNewPassword2] = useState("");

  let testStyle1 =
    "border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150";

  async function submitLoanRequest(id) {
    if (newPassword1 === "" || newPassword2 === "" || currentPassword === "")
      return toast.error("All fields are required");
    if (newPassword1 !== newPassword2)
      return toast.error("Passwords do not match");

    let data = {
      newPassword1,
      currentPassword,
    };

    toast.info("Processing");
    try {
      await userCP(data);

      toast.success("Password Change Successful");
        toast.info("Kindly Log in again with new password");
        localStorage.clear("eslSystem")
      window.location = "/auth/login";
    } catch (err) {
      toast.error(err.response?.data);
    }
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Change Password Form
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className={testStyle1}
                    placeholder="Enter Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className={testStyle1}
                    value={newPassword1}
                    placeholder="Enter New Password"
                    onChange={(e) => setNewPassword1(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className={testStyle1}
                    placeholder="Confirm New Password"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                  />
                </div>
              </div>
            </div>

<br />
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <button
                    type="button"
                    style={{ backgroundColor: "#558000", color: "white" }}
                    onClick={() => {
                      submitLoanRequest();
                    }}
                    className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
