import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
import { getLoanDetails } from "services/loanService";
import { processLoan } from "services/loanService";
import { getUser } from "services/userService";

// components

export default function LoanDisbursement() {
  let [loanDetails, setLoanDetails] = useState([]);
  let [requester, setRequester] = useState([]);
  let [proof, setProof] = useState();

  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const { data: details } = await getLoanDetails(id);
      const { data: requester } = await getUser(details[0].user_email);
      setLoanDetails(details[0]);
      setRequester(requester[0]);
    };
    fetchData().catch(console.error);
  }, []);

  let testStyle1 =
    "border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150";

  async function submitLoanRequest() {
    toast.info("Processing");

    let data = {
      status: "DISBURSED",
    };
    const formData = new FormData();
    formData.append("File", proof);
    formData.append("details", JSON.stringify(data));

    try {
      await processLoan(formData, id);

      toast.success("Loan Request Sent to Guarantor For Pre-Approval");
      toast.info("Kindly follow up with your guarantor for fast approval");
      window.location = "/admin/loans";
    } catch (err) {
      toast.error(err.response?.data);
    }
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Loan Disbursement
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <br />
            <button
              type="button"
              style={{
                backgroundColor: "#558000",
                color: "white",
                width: "auto",
              }}
              onClick={() => (window.location = "/admin/pendingLoans")}
              className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
            >
              Back
            </button>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Recipient Account Details
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Account Name:{requester?.account_name}
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bank Name: {requester?.bank_name}
                  </label>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Account Number: {requester?.account_number}
                  </label>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                  <label
                    className="block uppercase"
                    htmlFor="grid-password"
                    style={{ color: "green", fontSize: "larger" }}
                  >
                    <br />
                    Loan Value: {loanDetails?.amount?.toLocaleString(
                      "en-US"
                    )}{" "}
                    naira
                  </label>
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Submit Request
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Proof of Transfer of Funds
                  </label>
                  <input
                    type="file"
                    onChange={(e) => {
                      setProof(e.target.files[0]);
                    }}
                    className={testStyle1}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Action
                  </label>
                  <button
                    type="button"
                    style={{ backgroundColor: "#558000", color: "white" }}
                    onClick={() => {
                      submitLoanRequest();
                    }}
                    className=" active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  >
                    Submit Disbursement Proof
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
