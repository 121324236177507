const ColoredLine = ({ color }) => (
  <>
    <br />
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 2,
      }}
    />
    <br />
  </>
);

export default ColoredLine;
